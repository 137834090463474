.userForm {
  margin-bottom: 24px;

  .emailInput {
    max-width: 500px;

    &Title {
      margin-bottom: 2px;
    }

    &SubTitle {
      margin-bottom: 12px;
    }
  }

  .selectClient {
    width: 240px;

    &Title {
      margin: 12px 0;
    }
  }

  .otherClient {
    &Title {
      margin: 12px 0;
    }
  }

  .emailInputError {
    font-size: 12px;
    color: red;
    margin: 0px;
  }
}
