.checkItem {
  background-color: rgba(255, 255, 255, 0.1); /* Light transparent background */
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  backdrop-filter: blur(6px); /* Slight blur effect */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
  transition: background-color 0.3s;
}

.checkItem:hover {
  background-color: rgba(255, 255, 255, 0.15); /* Slightly brighter on hover */
}

.checkItem.success {
  border-left: 4px solid green;
}

.checkItem.fail {
  border-left: 4px solid red;
}

.checkItem.loading {
  border-left: 4px solid #1890ff;
}

.checkHeader {
  display: flex;
  align-items: center;
}

.loadingIcon {
  color: #1890ff;
  font-size: 20px;
  margin-right: 10px;
  animation: spin 1s linear infinite;
}

.successIcon,
.failIcon {
  font-size: 20px;
  margin-right: 10px;
}

.successIcon {
  color: green;
}

.failIcon {
  color: red;
}

.checkName {
  font-weight: 500;
  font-size: 18px;
  color: #333;
}

.checkError p {
  color: red;
  margin: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nestedChecks .ant-collapse-header {
  font-weight: bold;
  color: #1890ff;
  display: flex;
  align-items: center;
}

.nestedChecks .ant-collapse-content-box {
  padding: 10px;
}
