.container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;

  .headerContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .title {
    text-align: left;
    color: #333333;
    margin: 0px;
  }

  .reportCard {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid #e1e1e1;

    .reportHeader {
      display: flex;
      padding: 12px 16px;
      justify-content: space-between;
      border-bottom: 1px solid #e1e1e1;
      font-weight: 600;
      align-items: center;

      img {
        cursor: pointer;
      }

      .retryIcon {
        cursor: pointer;
      }
    }

    .reportFooter {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      border-top: 1px solid #e1e1e1;

      .checkedSVG {
        margin-right: 5px;
      }
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .contactSupport {
      margin-left: 16px;
    }
  }
}

.reports {
  flex: 1;
  padding: 10px 16px;

  img {
    margin-right: 10px;
  }

  .problem {
    display: flex;
    align-items: center;
    padding: 10px 0px;

    .icon {
      margin-right: 12px;
    }
  }
}

.checksList {
  margin-top: 10px;
}
