.ant-notification {
  left: 50% !important;
  top: auto !important;
  bottom: 0px !important;
  right: auto !important;
  transform: translateX(-50%);
  width: max-content;
}

.ant-notification .ant-notification-notice {
  border-radius: 4px;
  margin-bottom: 64px;
  width: fit-content;
  padding: 16px 14px;
  display: flex;
}

.ant-notification .ant-notification-notice-message {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  padding-right: 12px;
  width: auto;
}

.ant-notification .ant-notification-notice-close {
  position: unset;
  top: unset;
}
